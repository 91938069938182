import slugify from 'react-slugify';

export const palette = [
    {
        name: slugify("signature combo"),
        createdAt: 1,
        colors: [
            "#69d2e7","#a7dbd8","#e0e4cc","#f38630","#fa6900",
            "#fe4365","#fc9d9a","#f9cdad","#c8c8a9","#83af9b",
            "#ecd078","#d95b43","#c02942","#542437","#53777a",
            "#556270","#4ecdc4","#c7f464","#ff6b6b","#c44d58"
        ]
    },
    {
        name: slugify("signature combo 2"),
        createdAt:2,
        colors: [
            "#774f38","#e08e79","#f1d4af","#ece5ce","#c5e0dc",
            "#e8ddcb","#cdb380","#036564","#033649","#031634",
            "#490a3d","#bd1550","#e97f02","#f8ca00","#8a9b0f",
            "#594f4f","#547980","#45ada8","#9de0ad","#e5fcc2",
        ]
    },
    {
        name: slugify("signature combo 3"),
        createdAt:3,
        colors: [
            "#00a0b0","#6a4a3c","#cc333f","#eb6841","#edc951",
            "#e94e77","#d68189","#c6a49a","#c6e5d9","#f4ead5",
            "#d1f2a5","#effab4","#99b898","#ffffff","#eceabe",
            "#e8ddcb","#cdb380","#036564","#033649","#031634",
        ]
    },
    {
        name: slugify("bloom palette"),
        createdAt: 4,
        colors: [
            "#490a3d","#bd1550","#e97f02","#f8ca00","#8a9b0f",
            "#594f4f","#547980","#45ada8","#9de0ad","#e5fcc2",
            "#00a0b0","#6a4a3c","#cc333f","#eb6841","#edc951",
            "#e94e77","#d68189","#c6a49a","#c6e5d9","#f4ead5",
        ]
    },
    {
        name: slugify("Signature Combo 4"),
        createdAt: 5,
        colors: [
            "#774f38","#e08e79","#f1d4af","#ece5ce","#c5e0dc",
            "#e8ddcb","#cdb380","#036564","#033649","#031634",
            "#490a3d","#bd1550","#e97f02","#f8ca00","#8a9b0f",
            "#594f4f","#547980","#45ada8","#9de0ad","#e5fcc2",
        ]
    }
]